import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // * Check if in data of route exists roles array
    if (route.data['roles'] && route.data['roles'].length < 1) {
      return false;
    }

    // * Check if user has role required for current route
    if (route.data['roles'].some((requiredRoleId: number) => {
      return this.authService.currentUser.value.roles[0].id === requiredRoleId;
    })) {
      return true;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }
}
