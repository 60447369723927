<div class="loader-container flex-content-center flex-center" *ngIf="isLoading">
    <nz-spin nzSimple class="m-auto"></nz-spin>
</div>

<section class="page-body">
    <nz-layout>
        <app-header *ngIf="components.header"></app-header>
        <nz-layout *ngIf="components.sidebar; else noSidebar">
            <nz-sider nzCollapsible [nzCollapsed]="isCollapsed" class="sider-hidden-sm" [nzWidth]="siderWidth"
                      nzTheme="light"
                      (nzCollapsedChange)="toggleCollapsed()">
                <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
            </nz-sider>
            <nz-layout class="inner-layout">
                <router-outlet></router-outlet>
                <app-footer class="footer d-block d-xl-none" *ngIf="components.footer"></app-footer>
            </nz-layout>
        </nz-layout>
    </nz-layout>
</section>

<ng-template #noSidebar>
    <div class="d-flex flex-column flex-grow-1">
        <router-outlet></router-outlet>
        <app-footer class="footer d-block d-xl-none" *ngIf="components.footer"></app-footer>
    </div>
</ng-template>
