import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { AuthService } from './shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, firstValueFrom, of, tap } from 'rxjs';
import { FormattedResponse } from './shared/models/formatted-response';
import { InterceptorService } from './shared/helpers/interceptor.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  CaretLeftOutline,
  HomeOutline,
  InfoOutline,
  LockOutline,
  SettingOutline,
  StepBackwardOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';

const icons: IconDefinition[] = [StepBackwardOutline, CaretLeftOutline, SettingOutline, HomeOutline, InfoOutline, UserOutline, LockOutline];

registerLocaleData(it);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    NzIconModule.forChild(icons)
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),
    { provide: NZ_I18N, useValue: it_IT },
    { provide: LOCALE_ID, useValue: "it-IT" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, Router, ActivatedRoute],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializeAppFactory(auth: AuthService, router: Router, aRoute: ActivatedRoute): () => Observable<any> {
  // Get logged user if exists
  return () => auth.getUser(true).pipe(
    tap((response: FormattedResponse<any>) => {
      let user = response.data;
      auth.currentUser.next(user);
      // auth.redirectUser(router);
    }),
  );
}
