import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/guards/auth.guard';
import {RoleGuard} from "./shared/guards/role.guard";
import {Role} from "./shared/models/user.model";

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule)},
  {
    path: 'app',
    loadChildren: () => import('../app/modules/application/application.module').then(m => m.ApplicationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.USER]}
  },
  {
    path: 'back-office',
    loadChildren: () => import('../app/modules/backoffice/backoffice.module').then(m => m.BackofficeModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN]}
  },
  {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
  {path: '**', redirectTo: 'auth/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
