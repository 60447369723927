import { Component } from '@angular/core';
import { LoaderService } from './shared/helpers/loader.service';
import { delay, filter, map } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpContextConfig } from './shared/models/http-context-config';
import { config } from './environments/config';
import { environment } from './environments/environment';
import {it_IT} from "ng-zorro-antd/i18n";
import {BaseComponent} from "./components/base/base.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent {
  public isCollapsed: boolean = true;
  public isLoading: boolean = false;
  public siderWidth: string = '20rem';
  public components = {
    header: config.header.active,
    sidebar: config.sidebar.active,
    footer: config.footer.active
  };
  public footerType: string = "password-recovery";

  constructor(
    private _loading: LoaderService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
    this.i18nService.setLocale(it_IT);

    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function () { };
      console.warn = function () { };
      console.info = function () { };
      // console.error = function () { };
    }
    // translate.addLangs(['it', 'en']);
    // translate.setDefaultLang(this.currentLang);
    // translate.use(this.currentLang);
  }

  ngOnInit() {
    // verifica che tutti i componenti siano attivati correttamente secondo la configurazione
    this._router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)).subscribe((event: any) => {
        Object.keys(this.components).forEach(component => {
          this.isComponentActive(component, event);
          this.footerType = event;
        });
      });

    this.listenToLoading();

    let config = new HttpContextConfig();
    config.noSpinner = true;
    config.isPublic = true;
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  isComponentActive(component: string = 'header', url: string) {
    let configuration: string[] = config[component as keyof typeof config].exception ? config[component as keyof typeof config].exception : [];
    if (configuration.length > 0) {
      this.components[component as keyof typeof this.components] = !configuration.includes(url);
    }
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
